:root {
  --content-width: 100%;
}
@media screen and (min-width:600px) {
  :root {
    --content-width: 1200px;
  }
}

@media screen and (min-width:1441px) {
  :root {
    --content-width: 100%;
  }
  .middle-content-centered > .MuiBox-root {
    width: 90%;
  }
  .left {
    width: 480px !Important;
  }
}

html,
body,
.full-screen {
  height: 100%;
}
body {
  margin: 0;
}

/* In use for layout */
.full-screen {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}
.full-screen > * {
  flex: 0 0 auto;
  overflow: scroll;
}
.full-screen > main {
  flex: 1 1 auto;
  display: flex;
  overflow: hidden;
}
.full-screen > main > * {
  flex: 0 0 auto;
  overflow: auto;
}
.full-screen > main > .left {
  z-index: 1;
  width: 264px;
  background-color: #fff;
}
.full-screen > main > .middle {
  flex: 1 1 auto;
}

.middle-content-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: fit-content;
  /* height: calc(100% - 52px); */
}

.left nav a.active
{
  background: #F2EEED;
  box-shadow: inset 6px 0px 8px 1px rgba(0, 0, 0, 0.15);
}

.report-style-class {
	height: 100%;
  width: 1200px;
  flex-shrink: 0;
}

.report-style-class iframe {
  border: none;
  /* outline: 1px dotted #e73; */
}

/* ARC 1.x Scroll bar thumb is too thin */
::-webkit-scrollbar-thumb {
  border: 5px solid transparent !important;
}

.middle {
  display: grid;
  grid-template-columns: 1fr minmax( auto, var(--content-width)) 1fr ;
  grid-template-rows: auto auto 1fr;
}

@media screen and (max-width: 600px) {
  .MuiDataGrid-toolbarContainer>.MuiBox-root {
    display: none;
  }
  .MuiDataGrid-toolbarContainer>.MuiFormControl-root:last-child {
    flex: 1;
  }
  .MuiDataGrid-toolbarContainer .MuiInput-root .MuiInput-input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #AAAAAA; /* arc-grey/040 */
  }
  .MuiDataGrid-panelContent>.MuiDataGrid-filterForm {
    flex-direction: column;
  }
  .MuiDataGrid-panelContent>.MuiDataGrid-filterForm>.MuiDataGrid-filterFormDeleteIcon {
    align-items: flex-end
  }
  .MuiDataGrid-panelContent>.MuiDataGrid-filterForm>.MuiFormControl-root {
    width: 100%;
  }
  .banner .searchbox {
    width: 100%; 
  }
  .MuiTabs-flexContainer>button {
    padding: 0px 8px;
  }
  .MuiDataGrid-root .MuiDataGrid-virtualScrollerContent {
    width: 100% !IMPORTANT;
    padding: 8px;
  }
  .MuiDataGrid-virtualScrollerContent .MuiDataGrid-virtualScrollerRenderZone {
    gap: 8px;
    width: calc(100vw - 28px);
  }
  .MuiDataGrid-virtualScrollerRenderZone .MuiCardContent-root {/* arc-grey/040 */
      border: 1px solid #AAAAAA;
      /* height: 404px; */
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      background: #FAFAFA;
      padding: 0;
  }
  .MuiCardContent-root:last-child {
    padding-bottom: 0 !IMPORTANT;
  }
  .MuiCardContent-root hr.MuiDivider-root {
    width: 100%;
    border-top: 1px solid #AAAAAA;
  }
  .card-title {
      flex-grow: 1;
      width: 100%;
      gap: 16px !IMPORTANT;
      justify-content: space-around;
  }
  .card-title-frame {
      flex-grow: 1;
      justify-content: space-around;
  }
  .card-title-frame a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.25px;
    text-decoration-line: underline;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #005AAA;
  }
  .card-title-frame p {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.5px;
    color: #353535;
  }
  .card-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    justify-content: space-around;
    padding: 8px;
  }
  .card-content .card-content-label {
    font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: #353535; /* arc-grey/090 */
  }
  .card-content .card-content-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  
    display: flex;
    align-items: center;
    justify-self: right;
    text-align: right;
    letter-spacing: 0.4px;
    color: #353535; /* arc-grey/090 */
  }
  .card-footer {
    padding: 16px;
    height: 52px;
  }
  .card-footer>a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-decoration-line: underline;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #005AAA;
  }
  /* Overwrite the arc scrollbar */
  ::-webkit-scrollbar {
    width: 16px !important;
  }
}

@media screen and (min-width: 600px) {
  .middle {
    grid-template-rows: auto 1fr;
  }
  .banner .searchbox {
    min-width: 400px;
    width: 40%; 
  }
}
/* @media  screen and (min-width: 768px) and (max-width: 1199.98px) { ... } */

@media screen and (max-width: 1440px) {

  .full-screen > main > .left {
    width: 244px;
  }

  .report-style-class {
    width: 936px;
  }

  .middle-content-centered > .MuiBox-root {
    width: calc(936px - 28px);
  }
}

@media screen and (max-width: 600px) {
  .report-style-class {
    width: 100%;
  }
}

.middle > * {
  grid-column: 2;
}

.full-bleed  {
  padding: 0;
  max-height: 52px;
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: inherit;
  background-color: rgba(var(--arc-grey-090),1);
  color: rgba(var(--arc-blue-000),1);
}

.full-bleed  > * {
  grid-column: 2;
}

/* .hidden-header {
  display: none;
} */